import { get, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import connect from '../runtime-context/connect';

import { resolveId } from '@wix/communities-blog-client-common';
import { PaywallBanner } from '@wix/communities-blog-client-common/dist/esm/components';
import RichContentViewer from '../../../rich-content/components/rich-content-viewer';
import withFontClassName from '../../hoc/with-font-class-name';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../hoc/with-translate';
import { getAppSettings } from '../../selectors/app-settings-base-selectors';
import { getPaywallBanner } from '../../store/paywall/paywall-selectors';
import { getSection } from '../../selectors/section-selectors';
import styles from './post-content.scss';

export function PostContent({ t, post, contentFontClassName, appSettings, section, banner, navigateToPaidPlans } = {}) {
  const postContentBodyClass = 'post-content__body';
  return (
    <article className={contentFontClassName}>
      <div className={postContentBodyClass}>
        {typeof post.content === 'object' && (
          <RichContentViewer initialRawState={post.content} post={post} parentClass={postContentBodyClass} />
        )}
      </div>
      {get(post, 'canSeePaidContent') === false && banner && (
        <PaywallBanner
          viewer={RichContentViewer}
          appSettings={appSettings}
          section={section}
          banner={banner}
          className={styles.banner}
          onSubscribe={() =>
            navigateToPaidPlans(
              resolveId(post),
              post.paidPlansGroupIds,
              post.slug,
              t('subscription.thanks-page.title'),
              t('subscription.thanks-page.text'),
              t('subscription.thanks-page.continue-button'),
            )
          }
        />
      )}
    </article>
  );
}

PostContent.propTypes = {
  t: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  appSettings: PropTypes.object.isRequired,
  section: PropTypes.string,
  banner: PropTypes.object,
  navigateToPaidPlans: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  appSettings: getAppSettings(state),
  section: getSection(state, false),
  banner: getPaywallBanner(state),
  navigateToPaidPlans: actions.navigateToPaidPlans,
});

export default flowRight(connect(mapRuntimeToProps), withTranslate, withFontClassName, withPermissions)(PostContent);
