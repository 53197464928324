import { find } from 'lodash';
import { createSelector } from 'reselect';
import { getCategoryPath, getFeedTitle, getCategories } from '@wix/communities-blog-client-common';
import { getCurrentMatchPathname } from '../router/router-selectors';
import { getTotalPublicationsCount } from '../store/blog-stats/blog-stats-selectors';
import * as getHeaderLinksService from '../services/get-header-links';
import { getViewerAllPostsLabelTranslation } from './viewer-all-post-label-translation-selectors';

export const getFeedAndCategoryLinks = createSelector(
  getCategories,
  getFeedTitle,
  getViewerAllPostsLabelTranslation,
  getTotalPublicationsCount,
  getCategoryPath,
  (categories, feedTitle, translatedFeedTitle, totalPublicationsCount, categoryPath) =>
    getHeaderLinksService.getHeaderLinks({
      categories,
      feedTitle: translatedFeedTitle || feedTitle,
      categoryPath,
      totalPublicationsCount,
      withFeedLink: true,
    }),
);

export const getCategoryLinks = createSelector(
  getCategories,
  getFeedTitle,
  getCategoryPath,
  (categories, feedTitle, categoryPath) =>
    getHeaderLinksService.getHeaderLinks({
      categories,
      feedTitle,
      categoryPath,
      withFeedLink: false,
    }),
);

export const getActiveLink = createSelector(
  getFeedAndCategoryLinks,
  getCurrentMatchPathname,
  (links, currentPath) => find(links, (link) => link.path === currentPath) || links[0],
);
