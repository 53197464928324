import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Page from '../../../common/components/page';
import forDevice from '../../../common/hoc/for-device';
import styles from './post-page-layout.scss';

const PostPageLayoutMobile = ({ children, ...otherProps }) => <Page {...otherProps}>{children}</Page>;

PostPageLayoutMobile.propTypes = {
  children: PropTypes.node,
};

const PostPageLayoutDesktop = ({ children, className, ...otherProps }) => (
  <Page className={classNames(styles.container, className)} {...otherProps}>
    {children}
  </Page>
);

PostPageLayoutDesktop.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default forDevice(PostPageLayoutMobile, PostPageLayoutDesktop);
