import React, { Component } from 'react';

const PostPageWixCommentsUnsafe = React.lazy(() =>
  import(/* webpackChunkName: "post-page-wix-comments" */ './post-page-wix-comments'),
);

// Internal comments error handler should catch errors, but there still can be cases where error bubbles up because of bundling issues etc..
export class PostPageWixComments extends Component {
  state = { isInErrorState: false };
  componentDidCatch(error) {
    this.setState({ isInErrorState: true });
    console.error(error);
  }
  render() {
    if (this.state.isInErrorState) {
      return null;
    }
    return <PostPageWixCommentsUnsafe {...this.props} />;
  }
}
