import React from 'react';

import scrollToContent from '../../services/scroll-to-content';
import styles from './post-main-actions-comment-count-button.scss';
import commentsStyles from '../../../post-page/containers/post-page/post-page-wix-comments.scss';

export const CommentCountButton = ({ ariaLabel, commentCount, isExperimentScrollToCommentsEnabled }) =>
  isExperimentScrollToCommentsEnabled ? (
    <button
      aria-label={ariaLabel}
      onClick={() => scrollToContent(commentsStyles.container)}
      className={styles.commentsCountButton}
    >
      {commentCount}
    </button>
  ) : (
    commentCount
  );
