import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { AvatarPlaceholder } from '../icons/avatar-placeholder';
import styles from './fluid-avatar-image.scss';

const FluidAvatarImage = ({ image, ariaLabel }) =>
  image ? (
    <img
      aria-label={ariaLabel}
      className={classNames(styles.container, 'fluid-avatar-image')}
      src={image}
      data-hook="avatar-image"
    />
  ) : (
    <div aria-label={ariaLabel} className={classNames(styles.container, 'fluid-avatar-image')} data-hook="avatar-image">
      <AvatarPlaceholder className={styles.placeholder} />
    </div>
  );

FluidAvatarImage.propTypes = {
  image: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default FluidAvatarImage;
