import PropTypes from 'prop-types';
import React from 'react';

import { getContentText } from '../../services/content-text';
import styles from './simple-renderer.scss';

const Renderer = ({ contentState, excerpt, maxLength }) => {
  const text = excerpt ? excerpt.slice(0, maxLength) : getContentText(contentState).slice(0, maxLength);
  return (
    <p className={styles.content} data-hook="post-description">
      {text}
    </p>
  );
};

Renderer.propTypes = {
  contentState: PropTypes.object,
  excerpt: PropTypes.string,
  maxLength: PropTypes.number,
};

export default Renderer;
