import React from 'react';

import { EXPERIMENT_POST_PRINT } from '@wix/communities-blog-experiments';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';

import { connect } from '../runtime-context';
import { isEditor, isPreview } from '../../store/basic-params/basic-params-selectors';

import styles from './print-wrapper.scss';

const WIX_BLOG_HIDE_IN_PRINT = 'wix-blog-hide-in-print';
const WIX_BLOG_PRINT_IN_FULL_WIDTH = 'wix-blog-print-in-full-width';

const printCss = `
  @media print {
    .${WIX_BLOG_HIDE_IN_PRINT} {
      display: none !important;
    }
    .${WIX_BLOG_PRINT_IN_FULL_WIDTH} {
      left: 0 !important;
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    #site-root {
      top: 0 !important;
    }
  }`
  .replace(/\s+/g, ' ')
  .trim();

class PrintWrapper extends React.Component {
  printStyleElement = null;
  removePrintInFullWidth = [];
  removeHideInPrint = [];

  wrapperRef = React.createRef();

  componentDidMount() {
    const body = document.body;
    let currentElement = this.wrapperRef.current.parentElement;
    while (currentElement !== body) {
      currentElement.classList.add(WIX_BLOG_PRINT_IN_FULL_WIDTH);
      this.removePrintInFullWidth.push(currentElement);
      const parent = currentElement.parentElement;
      for (const child of parent.children) {
        if (child !== currentElement) {
          child.classList.add(WIX_BLOG_HIDE_IN_PRINT);
          this.removeHideInPrint.push(child);
        }
      }
      currentElement = parent;
    }

    this.printStyleElement = document.createElement('style');
    this.printStyleElement.type = 'text/css';
    this.printStyleElement.innerText = printCss;
    document.head.appendChild(this.printStyleElement);
  }

  componentWillUnmount() {
    if (this.printStyleElement) {
      document.head.removeChild(this.printStyleElement);
      this.printStyleElement = null;
    }
    this.removePrintInFullWidth.forEach((element) => element.classList.remove(WIX_BLOG_PRINT_IN_FULL_WIDTH));
    this.removePrintInFullWidth = [];
    this.removeHideInPrint.forEach((element) => element.classList.remove(WIX_BLOG_HIDE_IN_PRINT));
    this.removeHideInPrint = [];
  }

  render() {
    const { children } = this.props;

    return (
      <div className={styles.printContainer} ref={this.wrapperRef}>
        {children}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nonPrintEnv: isEditor(state) || isPreview(state),
    isPostPrintEnabled: isExperimentEnabled(state, EXPERIMENT_POST_PRINT),
  };
};

export default connect(mapStateToProps)(PrintWrapper);
