import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withRelativeTimeFormatting from '../../hoc/with-relative-time-formatting';
import withTranslate from '../../hoc/with-translate';

const TimeAgo = ({ className, time, timeFormatted, formatRelativeTime, t }) => {
  const timeAgo = t(timeFormatted || formatRelativeTime(time));

  return (
    <span title={timeAgo} className={classNames(className, 'time-ago')} data-hook="time-ago">
      {timeAgo}
    </span>
  );
};

TimeAgo.propTypes = {
  className: PropTypes.string,
  time: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  timeFormatted: PropTypes.string,
  formatRelativeTime: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default flowRight(withRelativeTimeFormatting, withTranslate)(TimeAgo);
